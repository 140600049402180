import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import SuspenseContent from "./Layouts/SuspenseContent";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { SocketProvider } from "./context/SocketContext";

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/* <SocketProvider> */}
      {/* <PersistGate loading={null} persistor={persistor}> */}
        <Suspense fallback={<SuspenseContent />}>
          <App />
        </Suspense>
      {/* </PersistGate> */}
    {/* </SocketProvider> */}
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
