import { useEffect, useState } from 'react'
import { RiAttachment2 } from 'react-icons/ri'
import { NavLink } from 'react-router-dom'
import { SuccesModal } from './SuccesModal'
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignUpCommon = () => {
  const [fileName, setFileName] = useState('Choose File');
  const role = sessionStorage.getItem("role");
  const [school, setSchool] = useState();

  let photo = "";
  if (role === "teacher") {
    photo = "teacherPhoto";
  } else {
    photo = "adminPhoto";
  }

  const [teacherObj, setTeacherObj] = useState({
    fullname: '',
    email: '',
    password: '',
    gender: '',
    mobile: '',
    dob: '',
    address: '',
    pincode: '',
    school: '',
    [photo]: "",
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTeacherObj(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const getSchools = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/other/get-schools`
      );
      console.log(response.data);
      setSchool(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getSchools();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault()

    const formData = new FormData();
    for (const key in teacherObj) {
      formData.append(key, teacherObj[key]);
    }
    console.log(formData);
    //log formdata
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${role}/signup`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          setIsModalOpen(true);
        })

      console.log(response.data);
      // Handle success (e.g., display success modal)
    } catch (error) {
      console.error('Error submitting form:', error)
      // Extract error message
      let errorMessage = "Error occurred";
      if (error.response?.data) {
        const errorData = error.response.data; // No need to parse
        if (errorData.errors && errorData.errors.length > 0) {
          errorMessage = errorData.errors[0].msg;
        }
      }
      toast.error(errorMessage, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file ? file.name : "Choose File");
    setTeacherObj({ ...teacherObj, [photo]: file });
  };

  const today = new Date().toISOString().split('T')[0] // Get today's date in YYYY-MM-DD format

  return (
    <div className='bg-white'>
      <div className=' h-screen justify-center items-center  bg-gray-100 text-gray-900 flex '>
        <div className='max-w-screen-xl shadow-lg bg-white rounded-3xl flex flex-col gap-5 justify-center py-4 px-10'>
          <div className=' '>
            <img
              className='w-14 h-14 mx-auto text-center'
              src='/onboarding/logo.png'
              alt='logo'
            />
            <p className='text-center text-neutral-700 text-[16px] font-normal mt-2'>
              Welcome to Focus Realm Portal
            </p>
          </div>

          <form className=' grid grid-cols-1 gap-y-5' onSubmit={handleSubmit}>
            <div className=' grid grid-cols-3 gap-4'>
              <div className='w-80 text-[14px] font-medium'>
                <label
                  htmlFor='fullname'
                  className='block mb-2 font-normal  text-gray-900'
                >
                  Full Name
                </label>
                <input
                  type='text'
                  id='fullname'
                  name='fullname'
                  className='bg-gray-50 border border-gray-300 text-stone-700 rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
                  placeholder='Your Full Name'
                  value={teacherObj.fullname}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className='w-80 text-[14px] font-medium'>
                <label
                  htmlFor='email'
                  className='block mb-2 font-normal  text-gray-900'
                >
                  Email Address
                </label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  className='bg-gray-50 border border-gray-300 text-stone-700 rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
                  placeholder='Email address'
                  value={teacherObj.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className='w-80 text-[14px] font-medium'>
                <label
                  htmlFor='gender'
                  className='block mb-2 font-normal  text-gray-900'
                >
                  Gender
                </label>
                <select
                  id='gender'
                  className='select  w-full  bg-white rounded-full border-gray-300'
                  name='gender'
                  value={teacherObj.gender}
                  onChange={handleInputChange}
                >
                  <option disabled value="">
                    Select
                  </option>
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                  <option value='Other'>Other</option>
                </select>
              </div>
            </div>
            <div className=' grid grid-cols-3 gap-4'>
              <div className='w-80 text-[14px] font-medium'>
                <label
                  htmlFor='mobile'
                  className='block mb-2 font-normal  text-gray-900'
                >
                  Mobile Number
                </label>
                <input
                  type='tel'
                  id='mobile'
                  name='mobile'
                  className='bg-gray-50 border border-gray-300 text-stone-700 rounded-full focus:outline-blue-500 block w-full p-2.5'
                  placeholder='Mobile number'
                  value={teacherObj.mobile}
                  onChange={e => {
                    const value = e.target.value
                    // Ensure the value is numeric and only 10 digits
                    if (/^\d{0,10}$/.test(value)) {
                      handleInputChange(e) // Only update if the value passes validation
                    }
                  }}
                  maxLength='10' // Maximum 10 digits
                  pattern='\d{10}' // Must be exactly 10 digits
                  required
                />
              </div>
              <div className='w-80 text-[14px] font-medium'>
                <label
                  htmlFor='password'
                  className='block mb-2 font-normal  text-gray-900'
                >
                  Password
                </label>
                <input
                  type='password'
                  id='password'
                  name='password'
                  className='bg-gray-50 border border-gray-300 text-stone-700 rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
                  placeholder='Set Password'
                  value={teacherObj.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className='w-80 text-[14px] font-medium col-span-1'>
                <label
                  htmlFor='dob'
                  className='block mb-2 font-normal  text-gray-900'
                >
                  Date Of Birth
                </label>
                <div className='relative'>
                  <input
                    type='date'
                    id='dob'
                    name='dob'
                    className='bg-gray-100 border border-gray-300 text-gray-900 rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 appearance-none'
                    value={teacherObj.dob}
                    onChange={handleInputChange}
                    max={today} // Restrict date to today or earlier
                  />
                </div>
              </div>
            </div>
            <div className=' grid grid-cols-3 gap-4'>
              <div className='w-80 text-[14px] font-medium col-span-1'>
                <label
                  htmlFor='school'
                  className='block mb-2 font-normal  text-gray-900'
                >
                  School Name
                </label>
                <select
                  id='school'
                  name='school'
                  className='select  w-full  bg-white rounded-full border-gray-300'
                  value={teacherObj.school}
                  onChange={handleInputChange}
                >
                  <option disabled value="">
                    Select
                  </option>
                  {school &&
                    school.length > 0 &&
                    school.map((schoolObj) => (
                      <option key={schoolObj._id} value={schoolObj._id}>
                        {schoolObj.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className='w-full text-[14px] font-medium col-span-2'>
                <label
                  htmlFor='address'
                  className='block mb-2 font-normal  text-gray-900'
                >
                  Address
                </label>
                <input
                  type='text'
                  id='address'
                  name='address'
                  className='bg-gray-50 border border-gray-300 text-stone-700 rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
                  placeholder='Address'
                  value={teacherObj.address}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className=' grid grid-cols-3 gap-4 '>
              <div className='text-[14px] font-medium'>
                <label
                  htmlFor='pincode'
                  className='block mb-2 text-sm font-normal text-gray-900'
                >
                  Pincode
                </label>
                <div className='relative'>
                  <div className='absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none'>
                    <svg
                      className='w-4 h-4 text-gray-500'
                      aria-hidden='true'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='currentColor'
                      viewBox='0 0 16 20'
                    >
                      <path d='M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z' />
                    </svg>
                  </div>
                  <input
                    type='text'
                    id='pincode'
                    name='pincode'
                    aria-describedby='helper-text-explanation'
                    className='bg-gray-50 border border-gray-300 text-gray-900 rounded-full focus:outline-blue-500 block w-full ps-10 p-2.5'
                    placeholder='PIN Code'
                    value={teacherObj.pincode}
                    onChange={e => {
                      const value = e.target.value
                      // Allow only numeric input and ensure max length of 5
                      if (/^\d{0,6}$/.test(value)) {
                        handleInputChange(e) // Update only if input is valid
                      }
                    }}
                    maxLength='6' // Allow maximum of 5 digits
                    pattern='^\d{6}$' // Ensure the pattern matches exactly 5 digits
                    required
                  />
                </div>
                <p
                  id='helper-text-explanation'
                  className='mt-2 text-sm text-gray-500 '
                >
                  Please enter a 6-digit number.
                </p>
              </div>

              <div className='text-[14px] font-medium'>
                <label
                  htmlFor='uploadphoto'
                  className='block mb-2 text-sm font-normal text-gray-900'
                >
                  Upload Photo
                </label>
                <div
                  className='relative border border-gray-300 rounded-full bg-white p-2.5 flex items-center justify-between cursor-pointer'
                  onClick={() => document.getElementById('uploadphoto').click()}
                >
                  <input
                    id='uploadphoto'
                    type='file'
                    accept=".png, .jpeg, .jpg"
                    className='hidden w-full cursor-pointer'
                    onChange={handleFileChange}
                  />
                  <span id='file-name w-full' className='text-gray-600'>
                    {fileName}
                  </span>
                  <RiAttachment2 className='text-2xl text-stone-700' />
                </div>
              </div>
              <div className='text-[14px] font-medium flex items-center'>
                <SuccesModal isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
              </div>
            </div>
          </form>
          <div>
            <p className='text-lg  text-center font-normal text-gray-500 '>
              Having an Focus Realm account?
              <NavLink
                to='/login'
                className='font-medium ps-2 text-primaryblue hover:underline cursor-pointer transition duration-200 '
              >
                Login here
              </NavLink>
            </p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default SignUpCommon
