// src/context/SocketContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { BASE_URL } from "../config.js";

const SocketContext = createContext();
// Get the role and _id from sessionStorage
const role = sessionStorage.getItem("role");
let user;
if(role === "teacher"){
  user = sessionStorage.getItem("teacher");
} else {
  user = sessionStorage.getItem("student");
}
let userId;

if (role === "teacher" && user) {
  const teacher = JSON.parse(sessionStorage.getItem("teacher"));
  userId = teacher[0]._id;
} 
if (role === "student" && user) {
  const student = JSON.parse(sessionStorage.getItem("student"));
  userId = student[0]._id;
}

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (userId) {
      const newSocket = io(BASE_URL, {
        query: {
          userId: userId,
        },
      });
      setSocket(newSocket);

      return () => newSocket.close();
    }
  }, [userId]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
