import { useEffect, useState } from 'react'
import { RiAttachment2 } from 'react-icons/ri'
import { NavLink } from 'react-router-dom'
import { SuccesModal } from './SuccesModal'
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignUpStudent = () => {
  const [batch, setBatch] = useState(null);
  const [sections, setSection] = useState();
  const [school, setSchool] = useState();
  const [fileName, setFileName] = useState("Choose File");
  const [studentObj, setStudentObj] = useState({
    fullname: "",
    email: "",
    gender: "",
    school: "",
    batch: "",
    section: "",
    rollno: "",
    mobileStudent: "",
    mobileParent: "",
    password: "",
    dob: "",
    address: "",
    pincode: "",
    studentPhoto: null,
  });

  const getClasses = async () => {
    try {
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/other/get-classes`, {
          schoolId: studentObj.school ? studentObj.school : null
        })
        .then((response) => {
          setBatch(response.data);
          console.log(response.data);
        })
    } catch (error) {
      console.log(error)
    }
  };

  const getSchools = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/other/get-schools`
      );
      console.log(response.data);
      setSchool(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getClasses();
  }, [studentObj.school]);

  useEffect(() => {
    getSchools();
  }, []);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/other/get-sections`, { classId: studentObj.batch ? studentObj.batch : null })
      .then((response) => {
        setSection(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [studentObj.batch]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = new FormData()
    for (const key in studentObj) {
      formData.append(key, studentObj[key])
    }

    //log formdata
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1])
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/student/signup`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      if (response.status === 200 || response.status === 201) {
        // Handle success (e.g., display success modal)
        setIsModalOpen(true); // Open the success modal
      }

      console.log(response.data)
      // Handle success (e.g., display success modal)
    } catch (error) {
      console.error('Error submitting form:', error)
      // Extract error message
      const errorMessage = error.response?.data?.message || "Error occurred";
      toast.error(errorMessage, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Ensure rollno is a positive number
    if (name === 'rollno' && (value < 0 || !Number.isInteger(Number(value)))) {
      return; // Do not update state if the value is not a positive integer
    }

    setStudentObj({
      ...studentObj,
      [name]: value,
    });
  };

  const handleFileChange = e => {
    const file = e.target.files[0]
    setFileName(file ? file.name : 'Choose File')
    setStudentObj({ ...studentObj, studentPhoto: file })
  }
  const today = new Date().toISOString().split('T')[0] // Get today's date in YYYY-MM-DD format

  return (
    <div className='bg-white'>
      <div className='h-screen justify-center items-center bg-gray-100 text-gray-900 flex'>
        <div className='max-w-screen-xl shadow-lg bg-white rounded-3xl flex flex-col gap-5 justify-center py-4 px-10'>
          <div>
            <img
              className='w-14 h-14 mx-auto text-center'
              src='/onboarding/logo.png'
              alt='logo'
            />
            <p className='text-center text-neutral-700 text-[16px] font-normal mt-2'>
              Welcome to Focus Realm Portal
            </p>
          </div>

          <form className='grid grid-cols-1 gap-y-3' onSubmit={handleSubmit}>
            <div className='grid grid-cols-3 gap-4'>
              <div className='w-80 text-[14px] font-medium'>
                <label
                  htmlFor='fullname'
                  className='block mb-2 font-normal text-gray-900'
                >
                  Full Name
                </label>
                <input
                  type='text'
                  id='fullname'
                  name='fullname'
                  className='bg-gray-50 border border-gray-300 text-stone-700 rounded-full focus:outline-blue-500 block w-full p-2.5'
                  placeholder='Your Full Name'
                  value={studentObj.fullname}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className='w-80 text-[14px] font-medium'>
                <label
                  htmlFor='email'
                  className='block mb-2 font-normal text-gray-900'
                >
                  Email Address
                </label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  className='bg-gray-50 border border-gray-300 text-stone-700 rounded-full focus:outline-blue-500 block w-full p-2.5'
                  placeholder='Email address'
                  value={studentObj.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className='w-80 text-[14px] font-medium'>
                <label
                  htmlFor='gender'
                  className='block mb-2 font-normal text-gray-900'
                >
                  Gender
                </label>
                <select
                  id='gender'
                  name='gender'
                  className='select w-full bg-white rounded-full border-gray-300'
                  value={studentObj.gender}
                  onChange={handleInputChange}
                  required
                >
                  <option disabled value=''>
                    Select
                  </option>
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                  <option value='Other'>Other</option>
                </select>
              </div>
            </div>
            <div className='grid grid-cols-3 gap-4'>
              <div className='w-80 text-[14px] font-medium'>
                <label
                  htmlFor='school'
                  className='block mb-2 font-normal text-gray-900'
                >
                  School Name
                </label>
                <select
                  id='school'
                  name='school'
                  className='select w-full bg-white rounded-full border-gray-300'
                  value={studentObj.school}
                  onChange={handleInputChange}
                  required
                >
                  <option disabled value=''>
                    Select
                  </option>
                  {school &&
                    school.length > 0 &&
                    school.map(schoolObj => (
                      <option key={schoolObj._id} value={schoolObj._id}>
                        {schoolObj.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className='w-80 text-[14px] font-medium'>
                <label
                  htmlFor='batch'
                  className='block mb-2 font-normal text-gray-900'
                >
                  Batch
                </label>
                <select
                  id='batch'
                  name='batch'
                  className='select w-full bg-white rounded-full border-gray-300'
                  value={studentObj.batch}
                  onChange={handleInputChange}
                  required
                >
                  <option disabled value=''>
                    Select
                  </option>
                  {batch &&
                    batch.length > 0 &&
                    batch.map(batchObj => (
                      <option key={batchObj._id} value={batchObj._id}>
                        {batchObj.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className='w-80 text-[14px] font-medium'>
                <label
                  htmlFor="batch"
                  className="block mb-2 font-normal text-gray-900">
                  Section
                </label>
                <select
                  id="section"
                  name="section"
                  className="select w-full bg-white rounded-full border-gray-300"
                  value={studentObj.section}
                  onChange={handleInputChange}
                  required>
                  <option disabled value="">
                    Select
                  </option>
                  {sections &&
                    sections.length > 0 &&
                    sections.map((sectionObj) => (
                      <option key={sectionObj._id} value={sectionObj._id}>
                        {sectionObj.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4">
              <div className="w-80 text-[14px] font-medium">
                <label
                  htmlFor="rollno"
                  className="block mb-2 font-normal text-gray-900">
                  Roll No
                </label>
                <input
                  type='number'
                  id='rollno'
                  name='rollno'
                  className='bg-gray-50 border border-gray-300 text-stone-700 rounded-full focus:outline-blue-500 block w-full p-2.5'
                  placeholder='0'
                  value={studentObj.rollno}
                  onChange={handleInputChange}
                  required
                  pattern="^[1-9][0-9]*$"
                />
              </div>
              <div className="w-80 text-[14px] font-medium">
                <label
                  htmlFor='mobileStudent'
                  className='block mb-2 font-normal text-gray-900'
                >
                  Mobile Number (Student)
                </label>
                <input
                  type='tel'
                  id='mobileStudent'
                  name='mobileStudent'
                  className='bg-gray-50 border border-gray-300 text-stone-700 rounded-full focus:outline-blue-500 block w-full p-2.5'
                  placeholder='Mobile number'
                  value={studentObj.mobileStudent}
                  onChange={e => {
                    const value = e.target.value
                    // Ensure the value is numeric and only 10 digits
                    if (/^\d{0,10}$/.test(value)) {
                      handleInputChange(e) // Only update if the value passes validation
                    }
                  }}
                  maxLength='10' // Maximum 10 digits
                  pattern='\d{10}' // Must be exactly 10 digits
                  required
                />
              </div>
              <div className='w-80 text-[14px] font-medium'>
                <label
                  htmlFor='mobileParent'
                  className='block mb-2 font-normal text-gray-900'
                >
                  Mobile Number (Parent)
                </label>
                <input
                  type='tel'
                  id='mobileParent'
                  name='mobileParent'
                  className='bg-gray-50 border border-gray-300 text-stone-700 rounded-full focus:outline-blue-500 block w-full p-2.5'
                  placeholder='Mobile number'
                  value={studentObj.mobileParent}
                  onChange={e => {
                    const value = e.target.value
                    // Ensure the value is numeric and only 10 digits
                    if (/^\d{0,10}$/.test(value)) {
                      handleInputChange(e) // Only update if the value passes validation
                    }
                  }}
                  maxLength='10' // Maximum 10 digits
                  pattern='\d{10}' // Must be exactly 10 digits
                  required
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4">
              <div className="w-80 text-[14px] font-medium">
                <label
                  htmlFor='password'
                  className='block mb-2 font-normal text-gray-900'
                >
                  Password
                </label>
                <input
                  type='password'
                  id='password'
                  name='password'
                  className='bg-gray-50 border border-gray-300 text-stone-700 rounded-full focus:outline-blue-500 block w-full p-2.5'
                  placeholder='Set Password'
                  value={studentObj.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="w-80 text-[14px] font-medium col-span-1">
                <label
                  htmlFor='dob'
                  className='block mb-2 font-normal text-gray-900'
                >
                  Date Of Birth
                </label>
                <div className='relative'>
                  <input
                    type='date'
                    id='dob'
                    name='dob'
                    className='bg-gray-100 border border-gray-300 text-gray-900 rounded-full focus:outline-blue-500 block w-full  p-2.5 appearance-none'
                    value={studentObj.dob}
                    onChange={handleInputChange}
                    max={today} // Restrict date to today or earlier
                  />
                </div>
              </div>
              <div className="text-[14px] font-medium">
                <label
                  htmlFor="uploadphoto"
                  className="block mb-2 text-sm font-normal text-gray-900">
                  Upload Photo
                </label>
                <div
                  className="relative border border-gray-300 rounded-full bg-white p-2.5 flex items-center justify-between cursor-pointer"
                  onClick={() =>
                    document.getElementById("uploadphoto").click()
                  }>
                  <input
                    id="uploadphoto"
                    type="file"
                    accept=".png, .jpeg, .jpg"
                    className="hidden w-full cursor-pointer"
                    onChange={handleFileChange}
                  />
                  <span id="file-name w-full" className="text-gray-600">
                    {fileName}
                  </span>
                  <RiAttachment2 className="text-2xl text-stone-700" />
                </div>
              </div>
              <div className='w-full text-[14px] font-medium col-span-2'>
                <label
                  htmlFor='address'
                  className='block mb-2 font-normal text-gray-900'
                >
                  Address
                </label>
                <input
                  type='text'
                  id='address'
                  name='address'
                  className='bg-gray-50 border border-gray-300 text-stone-700 rounded-full focus:outline-blue-500 block w-full p-2.5'
                  placeholder='Address'
                  value={studentObj.address}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="text-[14px] font-medium">
                <label
                  htmlFor='pincode'
                  className='block mb-2 text-sm font-normal text-gray-900'
                >
                  Pincode
                </label>
                <div className='relative'>
                  <div className='absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none'>
                    <svg
                      className='w-4 h-4 text-gray-500'
                      aria-hidden='true'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='currentColor'
                      viewBox='0 0 16 20'
                    >
                      <path d='M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z' />
                    </svg>
                  </div>
                  <input
                    type='text'
                    id='pincode'
                    name='pincode'
                    aria-describedby='helper-text-explanation'
                    className='bg-gray-50 border border-gray-300 text-gray-900 rounded-full focus:outline-blue-500 block w-full ps-10 p-2.5'
                    placeholder='PIN Code'
                    value={studentObj.pincode}
                    onChange={e => {
                      const value = e.target.value
                      // Allow only numeric input and ensure max length of 5
                      if (/^\d{0,6}$/.test(value)) {
                        handleInputChange(e) // Update only if input is valid
                      }
                    }}
                    maxLength='6' // Allow maximum of 5 digits
                    pattern='^\d{6}$' // Ensure the pattern matches exactly 5 digits
                    required
                  />
                </div>
                <p
                  id='helper-text-explanation'
                  className='mt-2 text-sm text-gray-500 '
                >
                  Please enter a 6-digit number.
                </p>
              </div>
            </div>
            <div className=" grid grid-cols-2 gap-4 items-center mx-auto justify-end">
              <div className="text-[14px] font-medium mx-auto flex items-center">
                <SuccesModal isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
              </div>
              <div>
                <p className="text-lg border-2 border-primaryblue rounded-full py-1.5 text-center font-normal text-gray-500 ">
                  <NavLink
                    to="/login"
                    className="font-medium ps-2 text-primaryblue hover:underline ">
                    Login here
                  </NavLink>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default SignUpStudent;
