import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import axios from 'axios';

const ResetPassword = () => {
  const [success, setSuccess] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const { token } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if passwords match
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return; // Prevent form submission
    }

    axios.post(`${process.env.REACT_APP_BASE_URL}/other/reset-password/${token}`, { newPassword })
      .then((response) => {
        console.log(response.data);
        // Simulate success
        setSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        // Reset the error if passwords match
        setError(error.response?.data?.message || 'An error occurred');
      });



    // Handle password reset logic here (e.g., API call)

  };

  return (
    <section className='bg-gray-100 flex flex-col items-center justify-center h-screen'>
      <div className='w-full p-6 md:mt-0 sm:max-w-md sm:p-8 bg-white rounded-lg shadow'>
        <div className='flex items-center justify-center mx-auto pb-5'>
          <img alt='logo' className='w-7' src='/onboarding/logo.png' />
          <h1 className='text-4xl font-semibold text-primaryblue pt-1'>
            Focus<span className='font-normal text-[#4F81D1]'>Realm</span>
          </h1>
        </div>

        {success ? (
          <div className='text-center'>
            <h2 className='text-xl font-semibold text-green-600'>
              Password reset successfully!
            </h2>
            <div className='text-9xl'>
              <FaCheckCircle className='text-green-500 mx-auto text-center py-5' />
            </div>
            <Link to='/login'>
              <button className='inline-block text-white w-full bg-primaryblue hover:bg-secondaryblue focus:ring-4 focus:outline-none focus:ring-primaryblue font-medium rounded-lg text-sm px-5 py-2.5'>
                Go to Login
              </button>
            </Link>
          </div>
        ) : (
          <div>
            <h2 className='mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl'>
              Change Password
            </h2>
            <form className='mt-4 space-y-4 lg:mt-5 md:space-y-5' onSubmit={handleSubmit}>
              <div>
                <label htmlFor='forgotpassword' className='block mb-2 text-sm font-medium text-gray-900'>
                  New Password
                </label>
                <input
                  type='password'
                  name='forgotpassword'
                  id='forgotpassword'
                  placeholder='••••••••'
                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primaryblue focus:border-primaryblue block w-full p-2.5'
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>
              <div>
                <label htmlFor='confirm-password' className='block mb-2 text-sm font-medium text-gray-900'>
                  Confirm password
                </label>
                <input
                  type='password'
                  name='confirm-password'
                  id='confirm-password'
                  placeholder='••••••••'
                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primaryblue focus:border-primaryblue block w-full p-2.5'
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    if (e.target.value === newPassword) {
                      setError('');
                    }
                  }}
                  required
                />
                {error && <p className='text-red-500 text-sm pt-2'>{error}</p>}
              </div>

              <button
                type='submit'
                className='w-full text-white bg-primaryblue hover:bg-secondaryblue focus:ring-4 focus:outline-none focus:ring-primaryblue font-medium rounded-lg text-sm px-5 py-2.5 text-center'
              >
                Reset Password
              </button>
            </form>
          </div>
        )}
      </div>
    </section>
  );
};

export default ResetPassword;
