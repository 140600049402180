import { createSlice } from "@reduxjs/toolkit";

const globalSearchSlice = createSlice({
    name: "globalSearch",
    initialState: {
        searchResults: [],
    },
    reducers: {
        setSearchResults: (state, action) => {
            state.searchResults = action.payload;
        },
        resetSearch: (state) => {
            state.searchResults = [];
        }
    },
});

export const { setSearchResults, resetSearch } = globalSearchSlice.actions;
export default globalSearchSlice.reducer;

