/* eslint-disable react/no-unescaped-entities */
import { Link, NavLink } from 'react-router-dom'
import { FiArrowRightCircle } from 'react-icons/fi'

export default function Home () {

  const handleRoleSelection = (role) => {
    sessionStorage.setItem('role', role);
  };

  return (
    <div className='bg-white'>
      <div className=' h-screen justify-center items-center bg-gray-100 text-gray-900 flex '>
        <div className='max-w-screen-xl  bg-white rounded-3xl flex justify-center flex-1 '>
          <div className=' w-full flex flex-col items-center justify-center py-5 gap-5'>
            <div className='flex items-center mb-3 text-2xl font-semibold text-gray-900 '>
              <img
                className='w-14 h-14 mr-2'
                src='/onboarding/logo.png'
                alt='logo'
              />
            </div>
            <p className='text-center text-neutral-700 text-[18px] font-normal '>
              Welcome to Focus Realm Portal
            </p>
            <div className='flex flex-col justify-center items-center gap-6 py-8'>
              <NavLink to='/login' className=''>
                <button
                  type='button'
                  className='text-black bg-sky-100 hover:bg-sky-300 focus:ring-4 focus:outline-none font-medium rounded-full text-[16px] px-5 py-2.5 text-center inline-flex items-center '
                  onClick={() => handleRoleSelection('student')} // Store 'student' role on click
                >
                  <FiArrowRightCircle className='text-2xl text-primaryblue me-2' />
                  Continue As student
                </button>
              </NavLink>
              <NavLink to='/login' className=''>
                <button
                  type='button'
                  className='text-black bg-sky-100 hover:bg-sky-300 focus:ring-4 focus:outline-none font-medium rounded-full text-[16px] px-5 py-2.5 text-center inline-flex items-center '
                  onClick={() => handleRoleSelection('admin')} // Store 'admin' role on click
                >
                  <FiArrowRightCircle className='text-2xl text-primaryblue me-2' />
                  Continue As Admin
                </button>
              </NavLink>
              <NavLink to='/login' className=''>
                <button
                  type='button'
                  className='text-black bg-sky-100 hover:bg-sky-300 focus:ring-4 focus:outline-none font-medium rounded-full text-[16px] px-5 py-2.5 text-center inline-flex items-center '
                  onClick={() => handleRoleSelection('teacher')} // Store 'admin' role on click
                >
                  <FiArrowRightCircle className='text-2xl text-primaryblue me-2' />
                  Continue As Teacher
                </button>
              </NavLink>
            </div>
            
          </div>
          <div className='w-full flex-1 bg-primaryblue text-center rounded-3xl p-16 text-white'>
            <img alt='img banner'
              className='max-w-xl w-[550px]'
              src="/onboarding/image36.png"
            />

            <h4 className='text-xl pb-2 font-semibold '>
              Welcome to the Focus Realm
            </h4>
            <p className='text-lg text-stone-300 font-normal leading-snug'>
              "Step into Focus Realm, Where Distractions Fade and Learning
              Thrives!"
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
