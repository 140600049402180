import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import checkAuth from './checkAuth';

const ProtectedRoute = () => {
  const isAuthenticated = checkAuth();
  console.log(isAuthenticated);
  if (!isAuthenticated) {
    alert('You are not authenticated');
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
