import { ColorRing } from "react-loader-spinner"
function SuspenseContent () {
  return (
    <div className='w-full h-screen text-gray-300  bg-white flex justify-center items-center'>
      <ColorRing
        visible={true}
        height='80'
        width='80'
        ariaLabel='color-ring-loading'
        wrapperStyle={{}}
        wrapperClass='color-ring-wrapper'
        colors={['#0148B7', '#00FFFF', '#ffffff', '#00BFFF', '#00BFFF']}
      />
    </div>
  )
}

export default SuspenseContent
