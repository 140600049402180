import { PiBarcode } from 'react-icons/pi'
import { Link } from 'react-router-dom'
/* eslint-disable react/no-unescaped-entities */
export default function Loginaceescode () {
  return (
    <div className='bg-white'>
      <div className=' h-screen justify-center items-center bg-gray-100 text-gray-900 flex '>
        <div className='max-w-screen-xl  bg-white rounded-3xl flex justify-center flex-1 '>
          <div className=' w-full flex flex-col items-center justify-center '>
            <div className='flex items-center mb-3 text-2xl font-semibold text-gray-900 '>
              <img
                className='w-14 h-14 mr-2'
                src='/onboarding/logo.png'
                alt='logo'
              />
            </div>
            <p className='text-center text-neutral-700 text-[16px] font-normal '>
              Welcome to Focus Realm Portal
            </p>
            <div className='w-full px-10 rounded-lg  '>
              <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
                <form className='space-y-4 md:space-y-6'>
                  <div className='text-[16px]'>
                    <label
                      htmlFor='accesscode'
                      className='block mb-2 font-medium text-gray-900'
                    >
                      Your Access Code
                    </label>
                    <div className='relative mb-6'>
                      <div className='text-2xl text-primaryblue absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none'>
                        <PiBarcode />
                      </div>
                      <input
                        type='text'
                        id='accesscode'
                        className='bg-white border border-gray-300 text-gray-900 rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-12 p-3  '
                        placeholder='eg. 333-222-333-444'
                      />
                    </div>
                  </div>
                  <button
                    // type='submit'
                    className='w-full text-white bg-primaryblue focus:ring-4 focus:outline-none focus:ring-primaryblue font-medium rounded-full text-lg px-5 py-3 text-center '
                  >
                    Login Now
                  </button>
                  <div>
                    <p className='text-lg  text-center font-normal text-gray-500 '>
                      Having an username and password?
                      <Link
                        to='/login'
                        className='font-medium ps-2 text-primaryblue hover:underline '
                      >
                        Login Here
                      </Link>
                    </p>
                  </div>

                  <div>
                    <p className='text-lg  text-center font-normal text-gray-500 '>
                      New to Focus Realm?
                      <Link
                        to='/signupstudent'
                        className='font-medium ps-2 text-primaryblue hover:underline '
                      >
                        Create an account
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className=' w-full flex-1 bg-primaryblue text-center rounded-3xl p-16 text-white'>
            <img alt=''
              className='max-w-xl'
              src='https://s3-alpha-sig.figma.com/img/8ef2/00c8/45ec2f99af063e943cea63c1273f2576?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bzp5pqRQHf9JPo6SI3F1gT~BO-BRDX34dIfYTyBHZvyD20ADNlSbj1yhKkkOxP~o1Tb~1zctzAv0s7duzJ29I89OEnN6nf1msbmoMaY8zibMlXo2ZgNqVQF~mi9ucUH2di0Qr5haCH7RnS9aCHAjFb15NED6pSyNl7CYf6DOPe7piWF5hBCxycG8nHyt4RENLm47UwN53X~1mM5BAsW2H47BiqTwTzT8nYnM7k8NeaUI2P2owAJ4F~aCw7VGympeFVcD5bEBb3h~zEQajs4Wib37bOkf8UXxt-wnjcmg4zoZ0nzU8MGQCZjmFfO30m5c-betOlmVxg8dEu7kJYEoRw__'
            />
            <h4 className='text-xl pb-2 font-semibold '>
              Welcome to the Focus Realm
            </h4>
            <p className='text-lg text-stone-300 font-normal leading-snug'>
              "Step into Focus Realm, Where Distractions Fade and Learning
              Thrives!"
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
