import { IoCloseCircleOutline } from "react-icons/io5";
import { BiLike } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
export function SuccesModal({ onClose, isModalOpen }) {

  useEffect(() => {
    const modal = document.getElementById("success_modal");
    if (isModalOpen) {
      modal.showModal();
    } else {
      modal.close();
    }
  }, [isModalOpen]);

  return (
    <>
      <button
        type="submit"
        className="w-full text-white bg-primaryblue hover:opacity-100 opacity-70 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 text-base rounded-full px-5 py-2.5"
        >
        Create Account
      </button>
      <dialog id="success_modal" className="modal ">
        <div className="modal-box bg-white max-w-sm p-10">
          <form method="dialog">
            <button className=" absolute right-4 top-4 hover:text-primaryblue hover:scale-105">
              <IoCloseCircleOutline className="text-3xl" />
            </button>
          </form>
          <div className="text-center flex flex-col justify-start items-center gap-4">
            <BiLike className="text-7xl text-center mx-auto p-4 rounded-full bg-green-300" />
            <h1 className="text-green-500 text-2xl">Success!!</h1>
            <p className="text-lg text-stone-700">
              Your account has been successfully created and it’s ready to use
            </p>
            <NavLink
              to="/login"
              className="w-full font-medium ps-2 rounded-full p-2 bg-primaryblue text-white hover:underline hover:bg-black"
              onClick={onClose} // Close the modal
            >
              Login Now
            </NavLink>
          </div>
        </div>
      </dialog>
    </>
  );
}
