const checkAuth = () => {
  const TOKEN = sessionStorage.getItem("jwtToken");
  const PUBLIC_ROUTES = [
    "/login",
    "/forgot-password",
    "/reset-password/:token",
    "/signupstudent",
    "/documentation",
    "/",
    "/signupcommon",
    "/loginaccesscode",
    "/adminhome"
  ];
  const pathname = window.location.pathname;

  // Check if the current path is a public route
  const isPublicPage = PUBLIC_ROUTES.includes(pathname);

  if (!TOKEN && !isPublicPage) {
    window.location.href = "/";
    return false;
  }

  return !!TOKEN;
};

export default checkAuth;
