import React, { lazy, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import initializeApp from "./app/init";
import Home from "./components/Home";
import SignUpStudent from "./components/user/SignUpStudent";
import ProtectedRoute from "./ProtectedRoute";
import SignUpCommon from "./components/user/SignUpCommon";
import Loginacesscode from "./components/user/Loginaceescode";
import { IntlProvider } from "react-intl";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import ResetPassword from './components/user/ResetPasword'

// Importing pages
const Layout = lazy(() => import("./Layouts/Layout"));
const Login = lazy(() => import("../src/components/user/Login"));
const ForgotPassword = lazy(() => import("./components/user/ForgotPassword"));


// Initializing different libraries
initializeApp();

function App() {
  useEffect(() => {
    themeChange(false);
  }, []);

  const { authUser } = useSelector(store => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authUser) {
      dispatch({ type: 'INIT_SOCKET', payload: { userId: authUser._id } });
    }
  }, [authUser, dispatch]);

  return (
    <IntlProvider locale="en">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/loginaccesscode" element={<Loginaceescode />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password/:token' element={<ResetPassword />} />
          <Route path="/signupstudent" element={<SignUpStudent />} />
          {/* <Route path="/documentation" element={<Documentation />} /> */}
          <Route path="/signupcommon" element={<SignUpCommon />} />
          <Route path="/loginaccesscode" element={<Loginacesscode />} />
          {/* <Route path="/teacherhome" element={<TeacherHome />} /> */}
          {/* Protected routes */}
          <Route element={<ProtectedRoute />}>
            {/* <Route path="/" element={<Layout />} /> */}
            <Route path="/*" element={<Layout />} />
          </Route>

          <Route
            path="*"
            element={
              <Navigate
                to={
                  sessionStorage.getItem("jwtToken") ? "/studenthome" : "/login"
                }
                replace
              />
            }
          />
        </Routes>
      </Router>
    </IntlProvider>
  );
}

export default App;
